import { OverlayTrigger, Tooltip } from "react-bootstrap";
interface Props {
    id: any,
    children: any,
    tooltip: string|any,
    place?: 'top' | 'bottom' | 'left' | 'right'
}
function TooltipComponent(props: Props) {
    const { id, children, tooltip, place='bottom' } = props
    return (
        <OverlayTrigger 
            overlay={<Tooltip  style={{zIndex:'50000' }} id={id}>{tooltip}</Tooltip>}
            placement={place }
            delay={{ show: 250, hide: 400 }}
        >
            {children}
        </OverlayTrigger>
    );
}
export { TooltipComponent }
