import {
  defaultQueryDateRangeParams,
  defaultQueryParams,
  defaultQueryParamsWithDates,
} from 'app/types'

export const ENDPOINTS = {
  CORDER: {
    LIST: '/corder/list',
    DETAIL: '/corder/detail',
    NEW: '/corder/new',
    SAVE: '/corder/save',
    BRANCH: {
      LIST: '/branch/list',
    },
    PRODUCT: {
      LIST: '/business_partner/products_sales_by_branch',
    },
  },
  BRANCH: {
    LIST: '/branch/list',
    DETAIL: '',
    SAVE: '',
    SALES: {
      LIST: '/branch/business_partner_sales_by_date_range_per_branch',
      DETAIL: '/branch/business_partner_product_sales_by_branch',
      NEW: '',
      SAVE: '',
      UPLOAD: '/business_partner/upload_file_cost',
    },
    XLS: {
      GET: '/business_partner/createXLSFile',
      SAVE: '',
    },
    PRODUCT: {
      SALES: {
        LIST: '/business_partner/product_sales_by_date_range',
      },
    },
  },
  SALES_CUT: {
    LIST: '/sale_cut/list',
    DETAIL: '/sale_cut/detail',
    UPLOAD: '',
    SAVE: '',
    INVOICE: {
      LIST: '',
      DETAIL: '/sale_cut_document/save_invoice_detail',
      NEW: '/sale_cut_document/save_invoice',
      SAVE: '',
      UPLOAD: '',
      DELETE: '/sale_cut_document/delete_invoice',
    },
    COMPLETE: {
      GET: '',
      SAVE: '',
    },
    EXCHANGE_RATES: {
      GET: '/exchange_rate/getRatesByExchangeIndicatorAndDateRange',
      SAVE: '',
    },
    EVENT_LOG: {
      GET: '/event_log/getEventLogBySaleCutDocument',
      SAVE: '',
    },
    CREDIT_MEMO: {
      GET: '',
      SAVE: '/sale_cut_document/update_creditmemo_pdf',
    },
  },
  SALES: {
    LIST: '',
    DETAIL: '',
    UPLOAD: '',
    SAVE: '',
    COSTS: {
      LIST: '/business_partner/cost',
      DETAIL: '',
      NEW: '',
      SAVE: '/business_partner/save_cost',
      UPLOAD: '',
    },
    XLS: {
      GET: '',
      SAVE: '',
    },
    PRODUCT: {
      SALES: {
        LIST: '',
      },
    },
  },
  PRODUCT: {
    LIST: ' ',
    DETAIL: '',
    UPLOAD: '',
    SAVE: '',
    SALES: {
      LIST: '',
      DETAIL: '',
      NEW: '',
      SAVE: '',
      UPLOAD: '',
    },
    XLS: {
      GET: '',
      SAVE: '',
    },
    PRODUCT: {
      SALES: {
        LIST: '',
      },
    },
  },
  BUSINESS_PARTNER_PRODUCT: {
    LIST: '/business_partner_product/list',
  },
  TREE: {
    TAX_LIST: '/tree/getTaxList',
  },
  COSTLIST: {
    NEW: '/mcostlist/getNewCostlist',
    SAVE: '/mcostlist/saveCostlist',
    APPROVE_PDF: '/mcostlist/approveCostlistPDF',
    REJECT: '/mcostlist/reject',
    UPDATE: '/mcostlist/updateCostlist',
    APPROVE: '/mcostlist/approve',
    DELETE: '/mcostlist/delete',
    LIST: '/mcostlist/list',
    DETAIL: '/mcostlist/detail',
    PRODUCTS: {
      REJECT: '/mcostlistproduct/reject',
      APPROVE: '/mcostlistproduct/approve',
      DELETE: '/mcostlistproduct/delete',
    },
  },
  EVENT_LOG: {
    BY_COSTLIST: '/event_log/getEventLogByCostlist',
  },
} as const

export type Endpoints = typeof ENDPOINTS

//TODO: lo nuevo
type ParamsMapping = {
  AUTH_GET_USER_BY_ACCESSTOKEN_URL: {api_token: string}
  AUTH_LOGIN_URL: {email: string; password: string; deviceName: string}
  AUTH_REGISTER_URL: {
    email: string
    firstname: string
    lastname: string
    password: string
    password_confirmation: string
  }
  AUTH_REQUEST_PASSWORD_URL: {email: string}
  BRANCH_COST_LIST: defaultQueryParams & {}
  BRANCH_COST_NEW: {}
  BRANCH_COST_SAVE: defaultQueryParams & {
    business_partner_cost_aux_id
    products
  }
  BRANCH_COST_UPLOAD: {}
  BRANCH_CREATE_XLS_FILE_TEMPLATE: {}
  BRANCH_DETAIL: {}
  BRANCH_GET_PRODUCT_LIST_URL: defaultQueryParamsWithDates
  BRANCH_HISTORY_COST: defaultQueryParamsWithDates & {business_partner_cost_id: string}
  BRANCH_HISTORY_PROMOTION: defaultQueryParamsWithDates & {business_partner_cost_id: string}
  BRANCH_LIST: {}
  BRANCH_PROMOTION_DETAILS: {business_partner_promotion_id: string}
  BRANCH_PROMOTION_LIST: defaultQueryParams
  BRANCH_PROMOTION_NEW: {}
  BRANCH_PROMOTION_SAVE: defaultQueryDateRangeParams & {
    business_partner_promotion_aux_id: string
    products: any
    name: string
    startdate: string
    enddate: string
    branch_id: string
  }
  BRANCH_PROMOTION_UPLOAD: {}
  BRANCH_PRODUCT_SALES_LIST: defaultQueryParamsWithDates
  BRANCH_SALES_DETAIL: defaultQueryParamsWithDates & {branch_id: string}
  BRANCH_SALES_LIST: defaultQueryParamsWithDates
  BRANCH_SALES_NEW: {}
  BRANCH_SALES_SAVE: {}
  BRANCH_SALES_UPLOAD: {
    sale_cut_id: number
    issue_date: string
    due_date: string
    document_number: string
    serial_number: string
    control_number: string
    base_amount: number
    tax_amount: number
    exento: number
    total: number
    exchange_rate: string
  }
  BRANCH_SAVE: {}
  BRANCH_UPLOAD_XLSCOST_FILE: defaultQueryParams & {uploaded_file: any; fileName: string}
  BRANCH_XLS_GET: {}
  BRANCH_XLS_SAVE: {}
  BUSINESS_PARTNER_COST_DETAILS: defaultQueryParams
  BUSINESS_PARTNER_PRODUCT_LIST: {}
  CORDER_BRANCH_LIST: {}
  CORDER_DETAIL: {c_order_id: string}
  CORDER_LIST: defaultQueryParamsWithDates
  CORDER_NEW: defaultQueryParams
  CORDER_PRODUCT_LIST: defaultQueryParamsWithDates
  CORDER_SAVE: defaultQueryParams
  COSTLIST_APPROVE: {m_costlist_id: string}
  COSTLIST_APPROVE_PDF: defaultQueryParams & {m_costlist_id: string}
  COSTLIST_DELETE: defaultQueryParams & {m_costlist_id: string}
  COSTLIST_DETAIL: {}
  COSTLIST_LIST: defaultQueryParamsWithDates
  COSTLIST_NEW: defaultQueryParams
  COSTLIST_PRODUCTS_APPROVE: defaultQueryParams & {
    m_costlist_id: string
    m_costlist_product_id: string
  }
  COSTLIST_PRODUCTS_DELETE: defaultQueryParams & {
    m_costlist_id: string
    m_costlist_product_id: string
  }
  COSTLIST_PRODUCTS_REJECT: defaultQueryParams & {
    m_costlist_id: string
    m_costlist_product_id: string
  }
  COSTLIST_REJECT: defaultQueryParams & {m_costlist_id}
  COSTLIST_SAVE: defaultQueryParams & {
    m_costlist_id: string
    costlist_pdf: any
    valid_since: string
    valid_until: string
    validity_days: number
    products: any[]
  }
  COSTLIST_TEMPLATE_GET: {}
  COSTLIST_UPDATE: defaultQueryParams & {
    m_costlist_id: string
    costlist_pdf: any
    valid_since: string
    valid_until: string
    validity_days: number
    products: any[]
  }
  COSTLIST_VERIFY_XLS: {}
  CREDIT_MEMO_STORE: defaultQueryParams & {
    currency_id: string
    trade_agreement_type_id: string
    issue_date: string
    due_date: string
    document_number: string
    invoice_number: string
    serial_number: string
    control_number: string
    base_amount: number
    tax: number
    tax_amount: number
    exempt_amount: number
    total: number
    exchange_rate: number
    file_pdf: any
  }
  CREDIT_MEMO_UPDATE: defaultQueryParams & {
    credit_memo_id: string
    currency_id: string
    trade_agreement_type_id: string
    issue_date: string
    due_date: string
    document_number: string
    invoice_number: string
    serial_number: string
    control_number: string
    base_amount: number
    tax: number
    tax_amount: number
    exempt_amount: number
    total: number
    exchange_rate: number
    file_pdf: any
  }
  CREDIT_MEMO_DELETE: defaultQueryParams & {credit_memo_id: string}
  CREDIT_MEMO_LIST: defaultQueryParamsWithDates & {status_code?: string[]}
  CREDIT_MEMO_VIEW: defaultQueryParams & {credit_memo_id: string}
  DETAIL: {}
  EVENT_LOG_BY_COSTLIST: {}
  EVENT_LOG_BY_CREDITMEMO: defaultQueryDateRangeParams & {
    credit_memo_id: string
    total_rows: number
  }
  LIST: {}
  MCOSTLIST_GET_PDFREJECT_REASONS_CL: defaultQueryParams & {
    m_costlist_id: string
    comments: string
    reject_code: string
  }
  MCOSTLIST_GET_PRODUCT_REASONS_CL: defaultQueryParams & {
    m_costlist_id: string
    m_costlist_product_id: string
    comments: string
    reject_code: number
  }
  PRODUCT_DETAIL: {}
  PRODUCT_LIST: {}
  PRODUCT_PRODUCT_SALES_LIST: {}
  PRODUCT_SALES_DETAIL: {}
  PRODUCT_SALES_LIST: {}
  PRODUCT_SALES_NEW: {}
  PRODUCT_SALES_SAVE: {}
  PRODUCT_SALES_UPLOAD: {}
  PRODUCT_SAVE: {}
  PRODUCT_UPLOAD: {}
  PRODUCT_XLS_GET: {}
  PRODUCT_XLS_SAVE: {}
  SALES_COSTS_DETAIL: {}
  SALES_COSTS_LIST: defaultQueryParams
  SALES_COSTS_NEW: {}
  SALES_COSTS_SAVE: defaultQueryParams & {
    business_partner_cost_aux_id: number
    products: any[]
  }
  SALES_COSTS_UPLOAD: {}
  SALES_CUT_COMPLETE: {sale_cut_id: string}
  SALES_CUT_COMPLETE_GET: {}
  SALES_CUT_COMPLETE_SAVE: {}
  SALES_CUT_CREDIT_MEMO_GET: {}
  SALES_CUT_CREDIT_MEMO_SAVE: defaultQueryParams & {
    sale_cut_document_id: string
    uploaded_file: any
  }
  SALES_CUT_DETAIL: {sale_cut_id: string}
  SALES_CUT_DOCUMENT_APPROVE_INVOICE_URL: defaultQueryParams & {
    sale_cut_document_id: string
    sale_cut_id: string
  }
  SALES_CUT_DOCUMENT_APPROVE_SALE_CUT_URL: defaultQueryParams & {
    sale_cut_id: string
  }
  SALES_CUT_DOCUMENT_GET_CREDIT_MEMO_REASONS: defaultQueryParams & {
    sale_cut_document_id: string
    reject_code: string
    sale_cut_id: string
    comments: string
  }
  SALES_CUT_DOCUMENT_GET_INVOICE_REASONS: {}
  SALES_CUT_DOCUMENT_REJECT_INVOICE_URL: defaultQueryParams & {
    sale_cut_document_id: string
    reject_code: string
    sale_cut_id: string
    comments: string
  }
  SALES_CUT_DOCUMENT_REJECT_SALE_CUT_URL: defaultQueryParams & {
    sale_cut_id: string
  }
  SALES_CUT_EVENT_LOG_GET: {
    sale_cut_document_id: string
  }
  SALES_CUT_EVENT_LOG_SAVE: {}
  SALES_CUT_EXCHANGE_RATES_GET: {
    exchange_indicator_symbol: string
    currency_symbol_from: string
    currency_symbol_to: string
    start_date_range: string
    end_date_range: string
  }
  SALES_CUT_EXCHANGE_RATES_SAVE: {}
  SALES_CUT_INVOICE_DELETE: defaultQueryParams & {
    sale_cut_document_id: string
  }
  SALES_CUT_INVOICE_DETAIL: defaultQueryParams & {
    sale_cut_document_number: string
    sale_cut_document_id: string
    discount: string
    products: any[]
    totalCreditMemo: string
    creditMemo: any[]
    /* creditMemo_${i}_sale_cut_document_id
creditMemo_${i}_document_number
creditMemo_${i}_control_number
creditMemo_${i}_base_amount
creditMemo_${i}_exempt_amount
creditMemo_${i}_tax_amount
creditMemo_${i}_amount
creditMemo_${i}_uploaded_file
creditMemo_${i}_filename */
  }
  SALES_CUT_INVOICE_LIST: {}
  SALES_CUT_INVOICE_NEW: {}
  SALES_CUT_INVOICE_PDF_GET: {}
  SALES_CUT_INVOICE_PDF_SAVE: defaultQueryParams & {
    sale_cut_document_id: string
    uploaded_file: any
  }
  SALES_CUT_INVOICE_SAVE: defaultQueryParams & {
    sale_cut_document_id: string
    uploaded_file: any
  }
  SALES_CUT_INVOICE_UPLOAD: {}
  SALES_CUT_LIST: defaultQueryParamsWithDates & {status_code: string}
  SALES_CUT_SAVE: {}
  SALES_CUT_UPLOAD: {}
  SALES_DETAIL: {}
  SALES_LIST: {}
  SALES_PRODUCT_SALES_LIST: {}
  SALES_SAVE: {}
  SALES_UPLOAD: {}
  SALES_XLS_GET: {}
  SALES_XLS_SAVE: {}
  TREE_TAX_LIST: {}
  TREE_TRADE_AGREEMENT_TYPE_LIST: {}
}
interface Endpoint<T extends keyof ParamsMapping> {
  url: string
  params: ParamsMapping[T]
}

export const endPointsWithParams: {[K in keyof ParamsMapping]: Endpoint<K>} = {
  AUTH_GET_USER_BY_ACCESSTOKEN_URL: {
    url: `/verify_token`,
    params: {} as ParamsMapping['AUTH_GET_USER_BY_ACCESSTOKEN_URL'],
  }, //?: GET_USER_BY_ACCESSTOKEN_URL
  AUTH_LOGIN_URL: {url: `/login`, params: {} as ParamsMapping['AUTH_LOGIN_URL']}, //?: LOGIN_URL
  AUTH_REGISTER_URL: {url: `/register`, params: {} as ParamsMapping['AUTH_REGISTER_URL']}, //?: REGISTER_URL
  AUTH_REQUEST_PASSWORD_URL: {
    url: `/forgot_password`,
    params: {} as ParamsMapping['AUTH_REQUEST_PASSWORD_URL'],
  }, //?: REQUEST_PASSWORD_URL
  BRANCH_COST_LIST: {
    url: `/business_partner/cost`,
    params: {} as ParamsMapping['BRANCH_COST_LIST'],
  }, //?: COST, COST_DETAILS
  BRANCH_COST_NEW: {url: '', params: {} as ParamsMapping['BRANCH_COST_NEW']}, //?:
  BRANCH_COST_SAVE: {
    url: `/business_partner/save_cost`,
    params: {} as ParamsMapping['BRANCH_COST_SAVE'],
  }, //?: SAVE_COST
  BRANCH_COST_UPLOAD: {url: '', params: {} as ParamsMapping['BRANCH_COST_UPLOAD']}, //?:
  BRANCH_CREATE_XLS_FILE_TEMPLATE: {
    url: `/business_partner/createXLSFile/:business_partner_id`,
    params: {} as ParamsMapping['BRANCH_CREATE_XLS_FILE_TEMPLATE'],
  }, //?: CREATE_XLS_FILE_TEMPLATE
  BRANCH_DETAIL: {url: '', params: {} as ParamsMapping['BRANCH_DETAIL']}, //?:
  BRANCH_GET_PRODUCT_LIST_URL: {
    url: `/business_partner/products_sales_by_branch`,
    params: {} as ParamsMapping['BRANCH_GET_PRODUCT_LIST_URL'],
  }, //?: PRODUCTS_SALES_BY_BRANCH, GET_PRODUCT_LIST_URL
  BRANCH_HISTORY_COST: {
    url: `/business_partner/list_history_cost`,
    params: {} as ParamsMapping['BRANCH_HISTORY_COST'],
  }, //?: LIST_HISTORY_COST
  BRANCH_HISTORY_PROMOTION: {
    url: `/business_partner/list_history_promotion`,
    params: {} as ParamsMapping['BRANCH_HISTORY_PROMOTION'],
  }, //?: LIST_HISTORY_PROMOTION
  BRANCH_LIST: {url: '/branch/list', params: {} as ParamsMapping['BRANCH_LIST']}, //?: GET_BRANCH_LIST_URL
  BRANCH_PROMOTION_DETAILS: {
    url: `/business_partner/promotion_details`,
    params: {} as ParamsMapping['BRANCH_PROMOTION_DETAILS'],
  }, //?: PROMOTION_DETAILS
  BRANCH_PROMOTION_LIST: {
    url: `/business_partner/promotion`,
    params: {} as ParamsMapping['BRANCH_PROMOTION_LIST'],
  }, //?: PROMOTION_URL
  BRANCH_PROMOTION_NEW: {url: '', params: {} as ParamsMapping['BRANCH_PROMOTION_NEW']}, //?:
  BRANCH_PROMOTION_SAVE: {
    url: `/business_partner/save_promotion`,
    params: {} as ParamsMapping['BRANCH_PROMOTION_SAVE'],
  }, //?: SAVE_PROMOTION
  BRANCH_PROMOTION_UPLOAD: {url: '', params: {} as ParamsMapping['BRANCH_PROMOTION_UPLOAD']}, //?:
  BRANCH_PRODUCT_SALES_LIST: {
    url: '/business_partner/product_sales_by_date_range',
    params: {} as ParamsMapping['BRANCH_PRODUCT_SALES_LIST'],
  }, //?: VENDORS_URL, PRODUCTS_URL, STATS_BY_PRODUCT
  BRANCH_SALES_DETAIL: {
    url: '/branch/business_partner_product_sales_by_branch',
    params: {} as ParamsMapping['BRANCH_SALES_DETAIL'],
  }, //?: GET_BRANCH_SALES_DETAIL_URL
  BRANCH_SALES_LIST: {
    url: '/branch/business_partner_sales_by_date_range_per_branch',
    params: {} as ParamsMapping['BRANCH_SALES_LIST'],
  }, //?: GET_BRANCH_SALES_LIST_URL, GET_SALES_BY_BRANCH_URL
  BRANCH_SALES_NEW: {url: '', params: {} as ParamsMapping['BRANCH_SALES_NEW']}, //?:
  BRANCH_SALES_SAVE: {url: '', params: {} as ParamsMapping['BRANCH_SALES_SAVE']}, //?:
  BRANCH_SALES_UPLOAD: {
    url: '/business_partner/upload_file_cost',
    params: {} as ParamsMapping['BRANCH_SALES_UPLOAD'],
  }, //?: UPLOAD_FILE_COST
  BRANCH_SAVE: {url: '', params: {} as ParamsMapping['BRANCH_SAVE']}, //?:
  BRANCH_UPLOAD_XLSCOST_FILE: {
    url: `/business_partner/uploadXLSCostFile`,
    params: {} as ParamsMapping['BRANCH_UPLOAD_XLSCOST_FILE'],
  }, //?: UPLOAD_XLSCOST_FILE
  BRANCH_XLS_GET: {
    url: '/business_partner/createXLSFile/:business_partner_id',
    params: {} as ParamsMapping['BRANCH_XLS_GET'],
  }, //?: CREATE_XLS_FILE_TEMPLATE
  BRANCH_XLS_SAVE: {url: '', params: {} as ParamsMapping['BRANCH_XLS_SAVE']}, //?:
  BUSINESS_PARTNER_PRODUCT_LIST: {
    url: '/business_partner_product/list',
    params: {} as ParamsMapping['BUSINESS_PARTNER_PRODUCT_LIST'],
  }, //?:
  BUSINESS_PARTNER_COST_DETAILS: {
    url: '/business_partner/cost_details',
    params: {} as ParamsMapping['BUSINESS_PARTNER_COST_DETAILS'],
  }, //?:
  CORDER_BRANCH_LIST: {url: '/branch/list', params: {} as ParamsMapping['CORDER_BRANCH_LIST']}, //?: GET_BRANCH_LIST_URL
  CORDER_DETAIL: {url: '/corder/detail', params: {} as ParamsMapping['CORDER_DETAIL']}, //?: GET_CORDER_DETAIL_URL
  CORDER_LIST: {url: '/corder/list', params: {} as ParamsMapping['CORDER_LIST']}, //?: GET_CORDER_LIST_URL
  CORDER_NEW: {url: '/corder/new', params: {} as ParamsMapping['CORDER_NEW']}, //?: POST_CORDER_NEW_URL, GET_ORDER_URL
  CORDER_PRODUCT_LIST: {
    url: '/business_partner/products_sales_by_branch',
    params: {} as ParamsMapping['CORDER_PRODUCT_LIST'],
  }, //?: PRODUCTS_SALES_BY_BRANCH, GET_PRODUCT_LIST_URL
  CORDER_SAVE: {url: '/corder/save', params: {} as ParamsMapping['CORDER_SAVE']}, //?: POST_CORDER_SAVE_URL, SAVE_ORDER_URL
  COSTLIST_APPROVE: {url: '/mcostlist/approve', params: {} as ParamsMapping['COSTLIST_APPROVE']}, //?: APPROVE_CL
  COSTLIST_APPROVE_PDF: {
    url: '/mcostlist/approveCostlistPDF',
    params: {} as ParamsMapping['COSTLIST_APPROVE_PDF'],
  }, //?: APPROVE_PDF_CL
  COSTLIST_DELETE: {url: '/mcostlist/delete', params: {} as ParamsMapping['COSTLIST_DELETE']}, //?: DELETE_CL
  COSTLIST_DETAIL: {url: '/mcostlist/detail', params: {} as ParamsMapping['COSTLIST_DETAIL']}, //?:
  COSTLIST_LIST: {url: '/mcostlist/list', params: {} as ParamsMapping['COSTLIST_LIST']}, //?:
  COSTLIST_NEW: {url: '/mcostlist/getNewCostlist', params: {} as ParamsMapping['COSTLIST_NEW']}, //?:
  COSTLIST_PRODUCTS_APPROVE: {
    url: '/mcostlistproduct/approve',
    params: {} as ParamsMapping['COSTLIST_PRODUCTS_APPROVE'],
  }, //?: APPROVE_PRODUCT_CL
  COSTLIST_PRODUCTS_DELETE: {
    url: '/mcostlistproduct/delete',
    params: {} as ParamsMapping['COSTLIST_PRODUCTS_DELETE'],
  }, //?: DELETE_PRODUCT_CL
  COSTLIST_PRODUCTS_REJECT: {
    url: '/mcostlistproduct/reject',
    params: {} as ParamsMapping['COSTLIST_PRODUCTS_REJECT'],
  },
  COSTLIST_REJECT: {url: '/mcostlist/reject', params: {} as ParamsMapping['COSTLIST_REJECT']}, //?: REJECT_CL, REJECT_PDF_CL
  COSTLIST_SAVE: {url: '/mcostlist/saveCostlist', params: {} as ParamsMapping['COSTLIST_SAVE']}, //?: SAVE_COST_LIST
  COSTLIST_TEMPLATE_GET: {
    url: '/mcostlist/{businessPartnerId}/xlsTemplateFileUrl',
    params: {} as ParamsMapping['COSTLIST_TEMPLATE_GET'],
  }, //?:
  COSTLIST_UPDATE: {
    url: '/mcostlist/updateCostlist',
    params: {} as ParamsMapping['COSTLIST_UPDATE'],
  }, //?: UPDATE_CL
  COSTLIST_VERIFY_XLS: {
    url: '/mcostlist/{businessPartnerId}/checkXlsCostlistFile',
    params: {} as ParamsMapping['COSTLIST_VERIFY_XLS'],
  }, //?:
  CREDIT_MEMO_STORE: {url: '/creditmemo/store', params: {} as ParamsMapping['CREDIT_MEMO_STORE']},
  CREDIT_MEMO_UPDATE: {
    url: '/creditmemo/{creditMemo}/update',
    params: {} as ParamsMapping['CREDIT_MEMO_UPDATE'],
  },
  CREDIT_MEMO_DELETE: {
    url: '/creditmemo/{creditMemo}/delete',
    params: {} as ParamsMapping['CREDIT_MEMO_DELETE'],
  },
  CREDIT_MEMO_LIST: {url: '/creditmemo/list', params: {} as ParamsMapping['CREDIT_MEMO_LIST']},
  CREDIT_MEMO_VIEW: {
    url: '/creditmemo/{creditMemo}',
    params: {} as ParamsMapping['CREDIT_MEMO_VIEW'],
  },

  DETAIL: {url: '/api/business-partners/:id', params: {} as ParamsMapping['DETAIL']}, //?:not
  EVENT_LOG_BY_COSTLIST: {
    url: '/event_log/getEventLogByCostlist',
    params: {} as ParamsMapping['EVENT_LOG_BY_COSTLIST'],
  }, //?:
  EVENT_LOG_BY_CREDITMEMO: {
    url: '/event_log/getEventLogByCreditMemo',
    params: {} as ParamsMapping['EVENT_LOG_BY_CREDITMEMO'],
  }, //?:
  LIST: {url: '/api/business-partners', params: {} as ParamsMapping['LIST']}, //?:not
  MCOSTLIST_GET_PDFREJECT_REASONS_CL: {
    url: `/tree/getCostlistRejectReasons`,
    params: {} as ParamsMapping['MCOSTLIST_GET_PDFREJECT_REASONS_CL'],
  }, //?: GET_PDFREJECT_REASONS_CL
  MCOSTLIST_GET_PRODUCT_REASONS_CL: {
    url: `/tree/getCostlistProductRejectReasons`,
    params: {} as ParamsMapping['MCOSTLIST_GET_PRODUCT_REASONS_CL'],
  }, //?: GET_PRODUCT_REASONS_CL
  PRODUCT_DETAIL: {url: '', params: {} as ParamsMapping['PRODUCT_DETAIL']}, //?:
  PRODUCT_LIST: {url: ' ', params: {} as ParamsMapping['PRODUCT_LIST']}, //?:
  PRODUCT_PRODUCT_SALES_LIST: {url: '', params: {} as ParamsMapping['PRODUCT_PRODUCT_SALES_LIST']}, //?:
  PRODUCT_SALES_DETAIL: {url: '', params: {} as ParamsMapping['PRODUCT_SALES_DETAIL']}, //?:
  PRODUCT_SALES_LIST: {url: '', params: {} as ParamsMapping['PRODUCT_SALES_LIST']}, //?:
  PRODUCT_SALES_NEW: {url: '', params: {} as ParamsMapping['PRODUCT_SALES_NEW']}, //?:
  PRODUCT_SALES_SAVE: {url: '', params: {} as ParamsMapping['PRODUCT_SALES_SAVE']}, //?:
  PRODUCT_SALES_UPLOAD: {url: '', params: {} as ParamsMapping['PRODUCT_SALES_UPLOAD']}, //?:
  PRODUCT_SAVE: {url: '', params: {} as ParamsMapping['PRODUCT_SAVE']}, //?:
  PRODUCT_UPLOAD: {url: '', params: {} as ParamsMapping['PRODUCT_UPLOAD']}, //?:
  PRODUCT_XLS_GET: {url: '', params: {} as ParamsMapping['PRODUCT_XLS_GET']}, //?:
  PRODUCT_XLS_SAVE: {url: '', params: {} as ParamsMapping['PRODUCT_XLS_SAVE']}, //?:
  SALES_COSTS_DETAIL: {url: '', params: {} as ParamsMapping['SALES_COSTS_DETAIL']}, //?:
  SALES_COSTS_LIST: {
    url: '/business_partner/cost',
    params: {} as ParamsMapping['SALES_COSTS_LIST'],
  }, //?: COST, COST_DETAILS
  SALES_COSTS_NEW: {url: '', params: {} as ParamsMapping['SALES_COSTS_NEW']}, //?:
  SALES_COSTS_SAVE: {
    url: '/business_partner/save_cost',
    params: {} as ParamsMapping['SALES_COSTS_SAVE'],
  }, //?: SAVE_COST
  SALES_COSTS_UPLOAD: {url: '', params: {} as ParamsMapping['SALES_COSTS_UPLOAD']}, //?:
  SALES_CUT_COMPLETE_GET: {url: '', params: {} as ParamsMapping['SALES_CUT_COMPLETE_GET']}, //?:
  SALES_CUT_COMPLETE_SAVE: {url: '', params: {} as ParamsMapping['SALES_CUT_COMPLETE_SAVE']}, //?:
  SALES_CUT_CREDIT_MEMO_GET: {url: '', params: {} as ParamsMapping['SALES_CUT_CREDIT_MEMO_GET']}, //?:
  SALES_CUT_CREDIT_MEMO_SAVE: {
    url: '/sale_cut_document/update_creditmemo_pdf',
    params: {} as ParamsMapping['SALES_CUT_CREDIT_MEMO_SAVE'],
  }, //?: UPDATE_SALE_CUT_DOCUMENT_CREDITMEMO_PDF_URL
  SALES_CUT_DETAIL: {url: '/sale_cut/detail', params: {} as ParamsMapping['SALES_CUT_DETAIL']}, //?: GET_SALE_CUT_DETAIL_URL
  SALES_CUT_DOCUMENT_APPROVE_INVOICE_URL: {
    url: `/sale_cut_document/approve_invoice`,
    params: {} as ParamsMapping['SALES_CUT_DOCUMENT_APPROVE_INVOICE_URL'],
  }, //?: APPROVE_INVOICE_URL
  SALES_CUT_DOCUMENT_APPROVE_SALE_CUT_URL: {
    url: `/sale_cut/approve`,
    params: {} as ParamsMapping['SALES_CUT_DOCUMENT_APPROVE_SALE_CUT_URL'],
  }, //?: APPROVE_SALE_CUT_URL
  SALES_CUT_DOCUMENT_GET_CREDIT_MEMO_REASONS: {
    url: `/tree/getSaleCutDocumentCreditMemoRejectReasons`,
    params: {} as ParamsMapping['SALES_CUT_DOCUMENT_GET_CREDIT_MEMO_REASONS'],
  }, //?: GET_CREDIT_MEMO_REASONS
  SALES_CUT_DOCUMENT_GET_INVOICE_REASONS: {
    url: `/tree/getSaleCutDocumentInvoiceRejectReasons`,
    params: {} as ParamsMapping['SALES_CUT_DOCUMENT_GET_INVOICE_REASONS'],
  }, //?: GET_INVOICE_REASONS
  SALES_CUT_DOCUMENT_REJECT_INVOICE_URL: {
    url: `/sale_cut_document/reject_invoice`,
    params: {} as ParamsMapping['SALES_CUT_DOCUMENT_REJECT_INVOICE_URL'],
  }, //?: REJECT_INVOICE_URL
  SALES_CUT_DOCUMENT_REJECT_SALE_CUT_URL: {
    url: `/sale_cut/reject`,
    params: {} as ParamsMapping['SALES_CUT_DOCUMENT_REJECT_SALE_CUT_URL'],
  }, //?: REJECT_SALE_CUT_URL
  SALES_CUT_EVENT_LOG_GET: {
    url: '/event_log/getEventLogBySaleCutDocument',
    params: {} as ParamsMapping['SALES_CUT_EVENT_LOG_GET'],
  }, //?: GET_SALE_CUT_DOCUMENT_EVENT_LOG_URL
  SALES_CUT_COMPLETE: {
    url: '/sale_cut/complete',
    params: {} as ParamsMapping['SALES_CUT_COMPLETE'],
  }, //?:
  SALES_CUT_EVENT_LOG_SAVE: {url: '', params: {} as ParamsMapping['SALES_CUT_EVENT_LOG_SAVE']}, //?:
  SALES_CUT_EXCHANGE_RATES_GET: {
    url: '/exchange_rate/getRatesByExchangeIndicatorAndDateRange',
    params: {} as ParamsMapping['SALES_CUT_EXCHANGE_RATES_GET'],
  }, //?: GET_EXCHANGE_RATES_URL
  SALES_CUT_EXCHANGE_RATES_SAVE: {
    url: '',
    params: {} as ParamsMapping['SALES_CUT_EXCHANGE_RATES_SAVE'],
  }, //?:
  SALES_CUT_INVOICE_DELETE: {
    url: '/sale_cut_document/delete_invoice',
    params: {} as ParamsMapping['SALES_CUT_INVOICE_DELETE'],
  }, //?: DELETE_SALE_CUT_INVOICE_URL
  SALES_CUT_INVOICE_DETAIL: {
    url: '/sale_cut_document/save_invoice_detail',
    params: {} as ParamsMapping['SALES_CUT_INVOICE_DETAIL'],
  }, //?: CREATE_SALE_CUT_INVOICE_DETAIL_URL
  SALES_CUT_INVOICE_LIST: {url: '', params: {} as ParamsMapping['SALES_CUT_INVOICE_LIST']}, //?:
  SALES_CUT_INVOICE_NEW: {
    url: '/sale_cut_document/save_invoice',
    params: {} as ParamsMapping['SALES_CUT_INVOICE_NEW'],
  }, //?: CREATE_SALE_CUT_INVOICE_URL
  SALES_CUT_INVOICE_PDF_GET: {url: '', params: {} as ParamsMapping['SALES_CUT_INVOICE_PDF_GET']}, //?:
  SALES_CUT_INVOICE_PDF_SAVE: {
    url: '/sale_cut_document/update_invoice_pdf',
    params: {} as ParamsMapping['SALES_CUT_INVOICE_PDF_SAVE'],
  }, //?: UPDATE_SALE_CUT_DOCUMENT_INVOICE_PDF_URL
  SALES_CUT_INVOICE_SAVE: {
    url: '/sale_cut_document/update_invoice',
    params: {} as ParamsMapping['SALES_CUT_INVOICE_SAVE'],
  }, //?: UPDATE_SALE_CUT_INVOICE_URL
  SALES_CUT_INVOICE_UPLOAD: {url: '', params: {} as ParamsMapping['SALES_CUT_INVOICE_UPLOAD']}, //?:
  SALES_CUT_LIST: {url: '/sale_cut/list', params: {} as ParamsMapping['SALES_CUT_LIST']}, //?: GET_SALES_CUTS_URL
  SALES_CUT_SAVE: {url: '', params: {} as ParamsMapping['SALES_CUT_SAVE']}, //?:
  SALES_CUT_UPLOAD: {url: '', params: {} as ParamsMapping['SALES_CUT_UPLOAD']}, //?:
  SALES_DETAIL: {url: '', params: {} as ParamsMapping['SALES_DETAIL']}, //?:
  SALES_LIST: {url: '', params: {} as ParamsMapping['SALES_LIST']}, //?:
  SALES_PRODUCT_SALES_LIST: {url: '', params: {} as ParamsMapping['SALES_PRODUCT_SALES_LIST']}, //?:
  SALES_SAVE: {url: '', params: {} as ParamsMapping['SALES_SAVE']}, //?:
  SALES_UPLOAD: {url: '', params: {} as ParamsMapping['SALES_UPLOAD']}, //?:
  SALES_XLS_GET: {url: '', params: {} as ParamsMapping['SALES_XLS_GET']}, //?:
  SALES_XLS_SAVE: {url: '', params: {} as ParamsMapping['SALES_XLS_SAVE']}, //?:
  TREE_TAX_LIST: {url: '/tree/getTaxList', params: {} as ParamsMapping['TREE_TAX_LIST']}, //?:
  TREE_TRADE_AGREEMENT_TYPE_LIST: {url: '/tree/getTradeAgreementType', params: {} as ParamsMapping['TREE_TRADE_AGREEMENT_TYPE_LIST']},   
}

export const endpoints = {
  auth: {
    GET_USER_BY_ACCESSTOKEN_URL: `/verify_token`,
    LOGIN_URL: `/login`,
    REGISTER_URL: `/register`,
    REQUEST_PASSWORD_URL: `/forgot_password`,
  },
  branch: {
    GET_BRANCH_LIST_URL: `/branch/list`,
    GET_BRANCH_SALES_LIST_URL: `/branch/business_partner_sales_by_date_range_per_branch`,
    GET_BRANCH_SALES_DETAIL_URL: `/branch/business_partner_product_sales_by_branch`,
  },
  businessPartner: {
    VENDORS_URL: `/business_partner/product_sales_by_date_range`,
    GET_SALES_BY_BRANCH_URL: `/branch/business_partner_sales_by_date_range_per_branch`,
    COST_DETAILS: `/business_partner/cost_details`, //*
    PROMOTION_URL: `/business_partner/promotion`,
    PRODUCTS_URL: `/business_partner/product_sales_by_date_range`,
    LIST_HISTORY_PROMOTION: `/business_partner/list_history_promotion`,
    LIST_HISTORY_COST: `/business_partner/list_history_cost`,
    PRODUCTS_SALES_BY_BRANCH: `/business_partner/products_sales_by_branch`,
    STATS_BY_PRODUCT: `/business_partner/product_sales_by_date_range`,
    PROMOTION_DETAILS: `/business_partner/promotion_details`,
    SAVE_PROMOTION: `/business_partner/save_promotion`,
    SAVE_COST: `/business_partner/save_cost`,
    COST: `/business_partner/cost`,
    UPLOAD_XLSCOST_FILE: `/business_partner/uploadXLSCostFile`,
    UPLOAD_FILE_COST: `/business_partner/upload_file_cost`,
    CREATE_XLS_FILE_TEMPLATE: `/business_partner/createXLSFile/`,
    GET_PRODUCT_LIST_URL: `/business_partner/products_sales_by_branch`, // Redundant, already in `VENDORS_URL`
  },
  corder: {
    GET_CORDER_LIST_URL: `/corder/list`,
    GET_CORDER_DETAIL_URL: `/corder/detail`,
    POST_CORDER_NEW_URL: `/corder/new`,
    POST_CORDER_SAVE_URL: `/corder/save`,
    SAVE_ORDER_URL: `/corder/save`,
    GET_ORDER_URL: `/corder/new`, // Redundant, already in `POST_CORDER_NEW_URL`
  },
  mcostlist: {
    SAVE_COST_LIST: `/mcostlist/saveCostlist`,
    APPROVE_PRODUCT_CL: `/mcostlistproduct/approve`,
    REJECT_PRODUCT_CL: `/mcostlistproduct/reject`,
    DELETE_PRODUCT_CL: `/mcostlistproduct/delete`,
    APPROVE_PDF_CL: `/mcostlist/approveCostlistPDF`,
    REJECT_PDF_CL: `/mcostlist/rejectCostlistPDF`,
    REJECT_CL: `/mcostlist/reject`,
    DELETE_CL: `/mcostlist/delete`,
    UPDATE_CL: `/mcostlist/updateCostlist`,
    APPROVE_CL: `/mcostlist/approve`,
    GET_PDFREJECT_REASONS_CL: `/tree/getCostlistRejectReasons`,
    GET_PRODUCT_REASONS_CL: `/tree/getCostlistProductRejectReasons`,
  },
  saleCut: {
    GET_SALES_CUTS_URL: `/sale_cut/list`,
    GET_SALE_CUT_DETAIL_URL: `/sale_cut/detail`,
    CREATE_SALE_CUT_INVOICE_URL: `/sale_cut_document/save_invoice`,
    CREATE_SALE_CUT_INVOICE_DETAIL_URL: `/sale_cut_document/save_invoice_detail`,
    DELETE_SALE_CUT_INVOICE_URL: `/sale_cut_document/delete_invoice`,
    UPDATE_SALE_CUT_DOCUMENT_CREDITMEMO_PDF_URL: `/sale_cut_document/update_creditmemo_pdf`,
    COMPLETE_SALE_CUT_URL: `/sale_cut/complete`, //*
    GET_EXCHANGE_RATES_URL: `/exchange_rate/getRatesByExchangeIndicatorAndDateRange`,
    GET_SALE_CUT_DOCUMENT_EVENT_LOG_URL: `/event_log/getEventLogBySaleCutDocument`,
    UPDATE_SALE_CUT_DOCUMENT_INVOICE_PDF_URL: `/sale_cut_document/update_invoice_pdf`,
    UPDATE_SALE_CUT_INVOICE_URL: `/sale_cut_document/update_invoice`,
  },
  saleCutDocument: {
    REJECT_INVOICE_URL: `/sale_cut_document/reject_invoice`,
    APPROVE_INVOICE_URL: `/sale_cut_document/approve_invoice`,
    GET_INVOICE_REASONS: `/tree/getSaleCutDocumentInvoiceRejectReasons`,
    GET_CREDIT_MEMO_REASONS: `/tree/getSaleCutDocumentCreditMemoRejectReasons`,
    APPROVE_SALE_CUT_URL: `/sale_cut/approve`,
    REJECT_SALE_CUT_URL: `/sale_cut/reject`,
  },
  tree: {
    GET_INVOICE_REASONS: `/tree/getSaleCutDocumentInvoiceRejectReasons`, // Already in `saleCutDocument`
    GET_CREDIT_MEMO_REASONS: `/tree/getSaleCutDocumentCreditMemoRejectReasons`, // Already in `saleCutDocument`
    GET_PDFREJECT_REASONS_CL: `/tree/getCostlistRejectReasons`, // Already in `mcostlist`
    GET_PRODUCT_REASONS_CL: `/tree/getCostlistProductRejectReasons`, // Already in `mcostlist`
    GET_TRADE_AGREEMENT_TYPE: `/tree/getTradeAgreementType`, // Already in `mcostlist`
  },
}
