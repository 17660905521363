import { create } from 'zustand'
import { AuthModel, UserModel, setAuth } from '../modules/auth'
import { Dispatch, SetStateAction } from 'react'
import * as authHelper from '../modules/auth/core/AuthHelpers'

/**
 * Tipos de estado y acciones para el store de autenticación
 */
type States = {
    /**
     * Información de autenticación del usuario
     */
    auth: AuthModel | undefined;
    /**
     * Usuario actualmente autenticado
     */
    currentUser: UserModel | undefined;
  };
  
  type Actions = {
    /**
     * Guarda la información de autenticación del usuario
     * @param auth Información de autenticación del usuario
     */
    saveAuth: (auth: AuthModel | undefined) => void;
    /**
     * Establece el usuario actualmente autenticado
     * @param user Usuario actualmente autenticado
     */
    setCurrentUser: (user: UserModel | undefined)=>void ;
    /**
     * Cierra la sesión del usuario
     */
    logout: () => void;
    /**
     * Verifica si el usuario tiene una permiso específico
     * @param user Usuario actualmente autenticado
     * @param permissionName Nombre del permiso a verificar
     * @returns Verdadero si el usuario tiene el permiso, falso en caso contrario
     */
    userCan: (user: UserModel | undefined, permissionName: string | undefined) => boolean;
    /**
     * Verifica si el usuario tiene un rol específico
     * @param user Usuario actualmente autenticado
     * @param roleName Nombre del rol a verificar
     * @returns Verdadero si el usuario tiene el rol, falso en caso contrario
     */
    userHas: (user: UserModel | undefined, roleName: string | undefined) => boolean;
    /**
     * Devuelve la ruta de destino del usuario según su rol
     * @param user Usuario actualmente autenticado
     * @returns Ruta de destino del usuario
     */
    userLandingRoute: (user: UserModel | undefined) => string;
  };
  
  /**
   * Crear el store de autenticación
   */
  const useAuthStore = create<States & Actions>((set,get) => ({
    /**
     * Estado inicial de autenticación
     */
    auth: authHelper.getAuth(),
    /**
     * Estado inicial del usuario actualmente autenticado
     */
    currentUser: undefined,
  
    /**
     * Guarda la información de autenticación del usuario
     * @param auth Información de autenticación del usuario
     */
    saveAuth: (auth: AuthModel | undefined) => {
      set({ auth });
      if (auth) {
        authHelper.setAuth(auth);
      } else {
        authHelper.removeAuth();
      }
    },
  
    /**
     * Establece el usuario actualmente autenticado
     * @param user Usuario actualmente autenticado
     */
    setCurrentUser: (user: UserModel | undefined) => {set(state=>({ currentUser: user }))},
  
    /**
     * Cierra la sesión del usuario
     */
    logout: () => {
        get().saveAuth(undefined);
        get().setCurrentUser(undefined);
    },
  
    /**
     * Verifica si el usuario tiene una permiso específico
     * @param user Usuario actualmente autenticado
     * @param permissionName Nombre del permiso a verificar
     * @returns Verdadero si el usuario tiene el permiso, falso en caso contrario
     */
    userCan: (user: UserModel | undefined, permissionName: string | undefined) => {
      if (!permissionName) return false;
      if (!user?.permissionsNames) return false;
      if (user.permissionsNames?.length < 1) return false;
      return user.permissionsNames.includes(permissionName);
    },
  
    /**
     * Verifica si el usuario tiene un rol específico
     * @param user Usuario actualmente autenticado
     * @param roleName Nombre del rol a verificar
     * @returns Verdadero si el usuario tiene el rol, falso en caso contrario
     */
    userHas: (user: UserModel | undefined, roleName: string | undefined) => {
      if (!roleName) return false;
      if (!user?.rolesNames) return false;
      if (user.rolesNames?.length < 1) return false;
      return user.rolesNames.includes(roleName);
    },
  
    /**
     * Devuelve la ruta de destino del usuario según su rol
     * @param user Usuario actualmente autenticado
     * @returns Ruta de destino del usuario
     */
    userLandingRoute: (user: UserModel | undefined) => {
      if (!user) return '/auth';
      if (user.rolesNames.includes('Supplier')) return '/dashboard';
      if (user.rolesNames.includes('Purchase Settler')) return '/supplier/sales/check';
      if (user.rolesNames.includes('Approver')) return '/supplier/costlist/list';
      if (user.rolesNames.includes('Buyer')) return '/supplier/costlist/list';//sololectura
      if (user.rolesNames.includes('Manager')) return '/supplier/costlist/list';//solo lectura
      if (user.rolesNames.includes('Administrator')) return '/dashboard';
      return '/error/403';
    },
  }));


// const inc = () => useStore.setState((state: any) => ({ bears: state.bears + 1 }))




export { useAuthStore }